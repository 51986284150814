<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>{{ $t('reportHistoryHeadline') }}</v-card-title>
          <v-card-text>{{ $t('reportHistorySubHeadline') }}</v-card-text>
          <v-card-actions>
            <v-btn x-small :href="VUE_APP_URL_HOME" color="primary" outlined>{{ $t('Scanapage') }}</v-btn>
          </v-card-actions>
          <v-card-title>
            <v-text-field v-model="search" :append-icon="mdiMagnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="historyForTable"
            :search="search"
            :loading="isLoading"
            :items-per-page="50"
            loading-text="Loading... Please wait"
            :footer-props="{
              itemsPerPageOptions: [50, 100, -1],
            }"
          >
            <template v-slot:item="props">
              <tr
                :style="{
                  color: props.item.thirdParty ? 'red' : props.item.session ? 'green' : '',
                }"
              >
                <td>{{ props.item.startHostname }}</td>
                <td v-if="isLoggedInArea">{{ props.item.numberOfThirdPartyHostnames || 0 }}</td>
                <td v-if="props.item.status != 'start'">{{ secondToMinute(props.item.crawlDurationInSec) }}</td>
                <td v-if="props.item.status === 'start'">
                  ~ {{ `${Math.ceil(props.item.statusDetails.estimatedMinutesToGo) || `...`} mins left` }}
                </td>
                <td v-if="props.item.status !== 'start'">{{ props.item.crawledPages }}</td>
                <td v-if="props.item.status === 'start'">
                  {{ `${props.item.statusDetails.done} / ${props.item.statusDetails.requested}` }}
                </td>
                <td v-if="isLoggedInArea && props.item.scanType === 'Comparsion'">
                  {{ props.item.countDiffOnPages }}
                </td>
                <td v-if="isLoggedInArea && props.item.scanType !== 'Comparsion'">
                  {{ $t('n.a.') }}
                </td>
                <td>
                  <v-progress-linear
                    v-if="props.item.status === 'start'"
                    color="primary"
                    rounded
                    height="6"
                    indeterminate
                  ></v-progress-linear>
                  <a
                    v-if="isLoggedInArea && props.item.status !== 'start'"
                    :href="`${SREPORTING_ROOT_PATH}${props.item.startHostname}/?ucrid=${props.item.crawlRequestId}`"
                    >{{ $t('seeDetails') }}</a
                  >
                  <a
                    v-if="!isLoggedInArea && props.item.status !== 'start'"
                    :href="`${SREPORTING_ROOT_PATH}${props.item.startHostname}/`"
                    >{{ $t('seeDetails') }}</a
                  >
                </td>
                <td v-if="isLoggedInArea">
                  {{ props.item.scanType === 'Comparsion' ? 'Comparison' : 'Scan' }}
                </td>
                <td v-if="isLoggedInArea">
                  {{ getFormattedDate(props.item.timeCrawlStart / 1000) }}
                </td>
                <td v-if="isLoggedInArea">
                  <v-icon small @click="deleteItem(props.item.crawlRequestId)"> {{ mdiDelete }} </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <v-card v-if="history.length === 0">
          <v-card-text
            >{{ $t('reportHistoryNoHistFound') }}
            <v-btn x-small :href="VUE_APP_URL_HOME" color="primary" outlined>{{ $t('Scanapage') }}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiMagnify, mdiDelete } from '@mdi/js';
import { getCrawlhistoryPublic, getCrawlhistoryPrivate } from '../utils/apiData';
import { getFormattedDate, secondToMinute } from '../utils/date';
import { isPathLoggedInArea } from '../utils/urls';

export default {
  name: 'ReportHistoryOverview',
  components: {},
  computed: {
    SREPORTING_ROOT_PATH: () => process.env.VUE_APP_URL_SUMMARY_REPORTING_ROOT_PATH,
    VUE_APP_URL_HOME: () => process.env.VUE_APP_URL_HOME,
    isLoading() {
      if (this.stillLoading === true) {
        return true;
      }
      return false;
    },
    isLoggedInArea() {
      return isPathLoggedInArea(this.$route.path);
    },
    historyForTable() {
      return this.history;
    },
    locale() {
      const userLang = navigator.language || navigator.userLanguage;
      return userLang;
    },
  },
  watch: {
    $route() {
      this.getHistory();
    },
  },
  data() {
    return {
      mdiMagnify,
      mdiDelete,
      host: 1,
      history: [],
      search: '',
      stillLoading: true,
      headers: [
        {
          text: 'Hostname',
          align: 'start',
          value: 'startHostname',
        },
        { text: this.$t('duration'), value: 'crawlDurationInSec' },
        { text: this.$t('pagesChecked'), value: 'crawledPages' },
        { text: this.$t('tableHeadSeeDetails'), value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      this.stillLoading = true;
      let result;
      if (this.isLoggedInArea) {
        this.headers.push({ text: this.$t('timeCrawlStart'), value: 'timeCrawlStart' });
        this.headers.splice(1, 0, { text: this.$t('no3rdPartyRequest'), value: 'numberOfThirdPartyHostnames' });
        this.headers.splice(4, 0, { text: this.$t('Pages with diff'), value: 'countDiffOnPages' });
        this.headers.splice(6, 0, { text: this.$t('Type'), value: 'scanType' });
        result = await getCrawlhistoryPrivate();
      } else {
        result = await getCrawlhistoryPublic();
      }

      if (!result.success) {
        this.$store.commit('message/setMessage', {
          color: 'error',
          message: `${result.data.message}`,
        });
        return;
      }
      this.history = [...result.data];
      this.stillLoading = false;
    },
    deleteItem(keyId) {
      const deletionObject = {
        keyId,
        ressource: 'pageCrawlEntry',
      };
      this.$store.commit('dialog/setName', { name: 'DialogDeleteItem', data: deletionObject });
    },
    getFormattedDate(date) {
      return getFormattedDate(date);
    },
    secondToMinute(seconds) {
      return secondToMinute(seconds);
    },
    niceChangesDetected(comparisonChangesDetected) {
      if (comparisonChangesDetected === -1) {
        return '-';
      }
      return comparisonChangesDetected;
    },
  },
  metaInfo: {
    title: 'History of scans - cookie-scanner.com',
    decription: 'See some websites already scanned and checked by cookie-scanner.',
  },
};
</script>
